@import "variables";

* {
    font-family: 'PT Root UI', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    font-style: normal;
    color: var(--text-primary);
}

h1 {
    font-weight: var(--bold);
    font-size: 3rem;
    margin-bottom: 48px;
}

h2 {
    font-weight: var(--bold);
    font-size: 2rem;
    margin-bottom: 38px;
}

h3 {
    font-weight: var(--bold);
    font-size: 1.5rem;
    margin-bottom: 28px;
}

h4 {
    font-weight: var(--bold);
    font-size: 1.25rem;
    margin-bottom: 28px;
}

h5 {
    font-weight: var(--bold);
    font-size: 1.125rem;
    margin-bottom: 28px;
}

h6 {
    font-weight: var(--bold);
    font-size: 1rem;
    margin-bottom: 28px;
}

label {
    color: var(--color-label);
    font-size: var(--text-xs);
}


@media screen and (max-width: 720px) {
    h1 {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 28px;
    }
}

//----------FONT WEIGHT----------
.font-normal {
    font-weight: var(--normal) !important;
}

.font-medium {
    font-weight: var(--medium) !important;
}

.font-bold {
    font-weight: var(--bold) !important;
}

.text-mono {
    font-feature-settings: 'tnum' on, 'lnum' on;
}
//------------------

.heading-1 {
    font-weight: var(--bold);
    font-size: 3rem;
    margin-bottom: 48px;
}

.heading-2 {
    font-weight: var(--bold);
    font-size: 2rem;
    margin-bottom: 38px;
}

.heading-3 {
    font-weight: var(--bold);
    font-size: 1.5rem;
    margin-bottom: 28px;
}

.heading-4 {
    font-weight: var(--bold);
    font-size: 1.125rem;
    margin-bottom: 28px;
}

.sub-head-1 {
    font-weight: var(--normal);
    font-size: 2rem;
    margin-bottom: 38px;
}

.sub-head-med-1 {
    font-weight: var(--medium);
    font-size: 2rem;
    margin-bottom: 38px;
}

.sub-head-2 {
    font-weight: var(--normal);
    font-size: 1.5rem;
    margin-bottom: 28px;
}

.sub-head-med-2 {
    font-weight: var(--medium);
    font-size: 1.5rem;
    margin-bottom: 28px;
}

.sub-head-med-2 {
    font-weight: var(--medium);
    font-size: 1.5rem;
    margin-bottom: 28px;
}

.sub-head-3 {
    font-weight: var(--normal);
    font-size: 1.25rem;
    margin-bottom: 28px;
}

.sub-head-med-3 {
    font-weight: var(--medium);
    font-size: 1.25rem;
    margin-bottom: 28px;
}

.body-1 {
    font-size: 1rem;
    font-weight: var(--normal);
}

.body-2 {
    font-size: .875rem;
    font-weight: var(--normal);
}

.caption {
    font-size: .75rem;
}

.page-title {
    font-weight: var(--bold);
    font-size: 2.375rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @media screen and (max-width: 992px) {
        font-size: 2.5rem;
        margin-top: 2rem;
        margin-bottom: 3.5rem;
    }

    @media screen and (max-width: 576px) {
        font-size: 2rem;
        margin-top: 1.5rem;
        margin-bottom: 3rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.75rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}

.sub-head {
    font-weight: var(--bold);
    font-size: 2rem;
    margin-bottom: 38px;

    @media screen and (max-width: 576px) {
        font-weight: var(--bold);
        font-size: 1.5rem;
        margin-bottom: 28px;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.25rem;
    }
}