.mainMenuList {
  border-radius: var(--br);

  .active {
    color: var(--primary);
    font-size: 1.25rem;

    .mainMenuItem {
      background-color: var(--background-hover) !important;
      color: inherit;

      .icon {
        color: inherit;
      }

      .title {
        color: inherit;
      }
    }
  }

  .inactive {
    color: var(--text-primary);
  }
}

.mainMenuItem {
  color: inherit;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  padding: 0;
  border-radius: var(--br) !important;

  .icon {
    color: var(--primary);
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
  }

  .title {
    color: var(--text-primary);
    align-self: center;
    font-weight: var(--medium);
  }

  &:hover {
    color: var(--primary);
    background-color: var(--hover-color);

    .title{
      color: var(--primary);
    }
  }
}

.mainMenuList li.mainMenuItem {
  &:hover {
    color: var(--primary);
    background-color: var(--hover-color);
  }
}

.logOutBtn {
  font-size: 1rem;

  & .mainMenuItem {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    color: var(--primary);
    border-radius: var(--br) !important;
    background-color: var(--text-50);

    .title{
      color: inherit;
    }

    .icon {
      color: inherit;
      stroke: var(--primary);
      stroke-width: var(--stroke-width);
      font-size: 1.5rem;
      line-height: 1.5rem;
      width: 42px;
      height: 42px;
    }
  }
}