.badge {
  z-index: 2;
  padding: 4px;
  //line-height: 12px;
  text-align: center;
  position: absolute !important;
  font-weight: 500;
  font-size: 10px;
  min-width: 1rem;
  height: 1rem;
  line-height: 8px;
  -webkit-text-fill-color: white;
}

.bAbsolute {
  //transform: translate(-10%, -40%);
  left: 100%;
}

.bRelative {
  transform: translate(-10%, -40%);
  padding-top: 5px;
}
