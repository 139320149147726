.modalDialog {
  .title {
    font-size: 28px;
    text-align: center;
    margin: 0;

    .subTitle {
      font-size: .6em;
      text-align: start;
      color: var(--caption-text-color);
    }
  }

  .body {
    margin: 2rem;
    margin-bottom: 0;

    p {
      padding: 0;
      margin: 0;
      margin-top: 1rem;
      font-size: 1rem;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;

    button {
      width: calc(calc(100% - 16px) * .5);
      margin: 0;
    }

    @media screen and (max-width: 576px) {
      button {
        width: 100%;
        height: 40px;
      }
    }
  }

  .modal-body {
    .modal-footer {
      padding: 0;
      padding-top: 2rem;
    }
  }
}

.modalDialog .btn-close {
  position: absolute !important;
  top: 1.5rem;
  right: 1.5rem;
}

.confirmDialog {
  .body {
    color: var(--color-text);
    font-weight: 600;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.003em;

    p {
      font-weight: 500;
    }
  }
}
