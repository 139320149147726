//override bootstrap variables
$border-radius: var(--unit);
$grid-gutter-width: 2rem;

// List group
$list-group-item-padding-x: .75rem;
$list-group-item-padding-y: .5rem;

// Accordion
$accordion-padding-x: .75rem;
$accordion-padding-y: .5rem;
$accordion-body-padding-y: 0;
$accordion-body-padding-x: 0;
$accordion-border-radius: var(--unit);
$accordion-icon-color: #167FFB;

//buttons
//$btn-padding-y: 50px;
$btn-disabled-opacity: .5;
$btn-font-family: var(--font-family);
$btn-focus-box-shadow: null;
$btn-border-radius: calc(var(--unit) * 1);
//$btn-hover-bg-shade-amount: 10%;
//$btn-hover-bg-tint-amount: 5%;

$font-family-sans-serif: 'PT Root UI', sans-serif !important;
$font-size-base: 1rem;

$h1-font-size: 3rem;
$h2-font-size: 2rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;

$text-muted: var(--text-600);

//tooltip
$tooltip-bg: var(--text-primary);
$tooltip-border-radius: var(--unit);

//dropdown
$dropdown-divider-bg: var(--color-divider);
$dropdown-padding-x: 1rem;
$dropdown-padding-y: 1rem;
$dropdown-link-hover-bg: var(--primary-50);
$dropdown-link-active-bg: var(--text-100);

//tooltip
$popover-bg: #001729;
$popover-body-color: #fff;
$popover-header-color: #fff;
$popover-border-width: 0;
$tooltip-bg: var(--text-promary);

//colors
$blue: hsl(212, 97%, 54%);
$secondary: hsl(208, 100%, 91%);
$danger: hsl(5, 98%, 52%);
$warning: rgb(255, 139, 15);
$success: hsl(135, 100%, 35%);
$body-color: hsl(206, 100%, 8%);
$border-color: hsl(205, 11%, 45%);
$component-active-bg: hsl(212, 97%, 54%);
$border-color: var(--color-border);

// Pagination
$pagination-active-bg: var(--secondary);
$pagination-active-color: var(--primary);

//Modal
$modal-header-padding-y: 2rem;
$modal-header-padding-x: 2rem;
$modal-header-padding: 2rem;
$modal-footer-padding: 2rem;
$modal-content-padding: 2rem;
$modal-inner-padding: 2rem;
$modal-content-border-color: none;
$modal-header-border-color: var(--color-divider);
$modal-footer-border-color: var(--color-divider);

//offcanvas
$offcanvas-padding-y: 1rem;
$offcanvas-padding-x: 1rem;
$offcanvas-horizontal-width: 320px;

//break points
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
  //xxxl: 2000px,
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
  //xxxl: 1920px,
);

:root {
  --bold: 700;
  --medium: 500;
  --normal: 400;

  --primary-800: #215CD4;
  --primary-700: #1E6EE7;
  --primary: #167FFB;
  --primary-500: #008FFF;
  --primary-400: #32A0FF;
  --primary-300: #5AB1FF;
  --primary-200: #AAD5FF;
  --primary-100: #D0E9FF;
  --primary-50: #E8F5FF;

  --disable-secondary: #D0E9FF;

  --error: #FC200C;
  --warning: #FF8B0F;
  --success: #00B22C;
  --info: #00AAF5;
  --violet: #8E44EA;

  --text-primary: #001729;
  --text-800: #334554;
  --text-700: #4D5D69;
  --text-600: #66747E;
  --text-500: #808B94;
  --text-400: #99A2A9;
  --text-300: #B3B9BF;
  --text-200: #CCD1D4;
  --text-100: #E5E8EA;
  --text-50: #F2F3F4;

  --secondary: var(--primary-100);
  --silver: var(--text-300);

  --background: #f6f6f6;
  --background-color: #ffffff;
  --background-hover: var(--primary-50);

  --color-label: var(--text-500);

  --color-border: var(--text-200);
  --color-divider: rgba(0, 23, 41, 0.16);
  --caption-text-color: var(--text-700);

  --href-color: var(--primary);

  --unit: 4px;

  --br: calc(var(--unit) * 1);

  --bs-popover-arrow-border: #2f2f2f;
  --color-blocking: #ffffffcc;
  --padding-top-page: 60px;
  --top-margin-page: 90px;

  --stroke-width: .5;

  --font-family: 'PT Root UI', sans-serif;

  @media screen and (max-width: 992px) {
    --top-margin-page: 80px
  }

  @media screen and (max-width: 576px) {
    --top-margin-page: 70px
  }
}