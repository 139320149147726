.banner {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  line-height: 22px;
  letter-spacing: 0.005em;
  box-shadow: rgba(154, 178, 202, 0.43) 1px 1px 20px 4px;
  border-radius: var(--br);
  padding: 5px 15px;
  z-index: 10000;
  background-color: white;
  gap: 1rem;

  .helpers {
    margin: 0;
    padding: 0;
    line-height: 14px;
    font-size: 14px;
    color: #ff7474;
  }

  .delayBlock {
    display: flex;

    .circular {
      height: 40px;
      width: 40px;
      margin: auto;
      box-shadow: #ff6666 0 0 10px 0;
      border-radius: 50%;

      text {
        font-weight: 600;
      }
    }

    .pinPongAnim {
      animation: pinPongAnim linear 1s infinite;
    }

    .endTimerAnim {
      animation: endTimerAnim ease-out .8s forwards;
    }

    .textDisplay {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 10px;

      .counter {
        padding-top: 4px;
      }

      .minute {
        font-size: 10px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    margin: 0 1rem;
  }
}

@keyframes pinPongAnim {
  0% {
    box-shadow: #ff6666 0 0 10px 0;
  }
  50% {
    box-shadow: #ff6666 0 0 10px 2px;
  }
  100% {
    box-shadow: #ff6666 0 0 10px 0;
  }
}

@keyframes endTimerAnim {
  0% {
    box-shadow: #ff6666 0 0 0 0;
    opacity: 1;
    fill-opacity: 1;
  }
  50% {
    box-shadow: #ff6666 0 0 20px 0;
    opacity: 1;
    fill-opacity: 1;
  }
  100% {
    box-shadow: #ff6666 0 0 0 0;
    opacity: 0;
    fill-opacity: 0;
  }
}

@keyframes hideDataBlocAnim {
  0% {
    opacity: 1;
    fill-opacity: 1;
  }
  100% {
    opacity: 0;
    fill-opacity: 0;
    display: none;
  }
}

@keyframes hideAnim {
  0% {
    display: block;
    opacity: 1;
    fill-opacity: 1;
  }
  50% {
    fill-opacity: 1;
  }
  99% {
    transform: scaleX(0);
  }
  100% {
    opacity: 0;
    fill-opacity: 0;
    display: none;
  }
}

.hideBanner {
  width: auto;
  transition: all 1.5s ease-in 0s;
  animation: hideAnim linear .3s forwards 1s;

  .banner {
    div {
      animation: hideDataBlocAnim cubic-bezier(.5, 0, 1, 1) 1s forwards;
    }
  }
}

.bannerWrap {
  z-index: 30;
  position: fixed;
  bottom: 20px;
  left: 50%;
  margin-right: -50%;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);

  .close {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 999999;
    fill: color-mix(in srgb, var(--primary), #3f3b3b);

    &:hover {
      fill-opacity: .8;
    }
  }

  @media screen and (max-width: 540px) {
   .close {
     right: 8px
   }
  }
}

@mixin slideToRightParams($i) {
  0% {
    opacity: 0;
  }
  50% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(20% * $i);
    opacity: 1;
  }
}

@for $i from 1 through 3 {
  @keyframes slideToRight#{$i} {
    @include slideToRightParams($i)
  }
  @-khtml-keyframes slideToRight#{$i} {
    @include slideToRightParams($i)
  }
  @-moz-keyframes slideToRight#{$i} {
    @include slideToRightParams($i)
  }
  @-ms-keyframes slideToRight#{$i} {
    @include slideToRightParams($i)
  }
  @-o-keyframes slideToRight#{$i} {
    @include slideToRightParams($i)
  }
  @-webkit-keyframes slideToRight#{$i} {
    @include slideToRightParams($i)
  }
}

@-webkit-keyframes arrow-down-anim {
  0% {
    fill: white;
  }
  50% {
    fill: rgba(255, 255, 255, 0.5);
  }

  100% {
    fill: white;
  }
}

@-o-keyframes arrow-down-anim {
  0% {
    fill: white;
  }
  50% {
    fill: rgba(255, 255, 255, 0.5);
  }

  100% {
    fill: white;
  }
}

@-moz-keyframes arrow-down-anim {
  0% {
    fill: white;
  }
  50% {
    fill: rgba(255, 255, 255, 0.5);
  }

  100% {
    fill: white;
  }
}

@keyframes arrow-down-anim {
  0% {
    fill: white;
  }
  50% {
    fill: rgba(255, 255, 255, 0.5);
  }

  100% {
    fill: white;
  }
}

@keyframes first-show-btn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bannerMain {
  height: 100vh;
  position: relative;

  @media screen and (max-width: 720px) {
    height: 320px;
  }

  .backBannerMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../../assets/bg_header_full.webp");
    background-size: cover;
    height: 100vh;
    margin-left: -10px;
    margin-top: 5rem;
    position: absolute;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);

    .btnDown {
      width: 240px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      border-radius: 50px;
      box-shadow: rgba(255, 255, 255, 0.77) 1px 1px 40px 30px;
      animation: first-show-btn 1s linear;

      svg {
        width: 26px;
        height: 20px;
        margin: 10px 0;
        animation: arrow-down-anim 800ms ease-in-out infinite;
      }

      &:hover {
        font-weight: 600;
      }

      &:active {
        color: rgba(255, 255, 255, 0.7);

        svg {
          fill: rgba(255, 255, 255, 0.7);
        }
      }
    }

    h1 {
      color: white;
      font-size: 6rem;
      font-weight: 700;
      width: fit-content;
      text-shadow: 0 0 20px black;
      margin-left: 5rem;
      margin-right: 5rem;
      line-height: initial;
      transform: translateX(0%);
    }

    @media screen and (max-width: 3440px) {
      @for $i from 1 through 3 {
        .word#{$i} {
          animation: slideToRight#{$i} 1s linear forwards;
        }
      }
    }

    @media screen and (max-width: 1600px) {
      h1 {
        font-size: 5rem;
      }
    }

    @media screen and (max-width: 1400px) {
      h1 {
        font-size: 4rem;
      }
    }

    @media screen and (max-width: 1200px) {
      h1 {
        font-size: 4rem;
        transform: translateX(0%) !important;
      }
    }

    @media screen and (max-width: 970px) {

    }

    @media screen and (max-width: 720px) {
      height: 320px;

      h1 {
        font-size: 3rem;
        margin: 0 5rem;
      }

      .btnDown {
        display: none;
      }
    }

    @media screen and (max-width: 480px) {
      margin-top: 4rem;
      height: 336px;

      h1 {
        font-size: 2rem;
      }
    }

    @media screen and (max-width: 320px) {
      h1 {
        margin: 0 4rem;
      }

      .btnDown {
        display: none;
      }
    }
  }

  .tagScroll {
    bottom: 0;
    position: absolute;
  }
}
