.mainContainer {
  margin: 0 auto !important;
  background-color: var(--background-color);
  border-radius: 0 0 var(--br) var(--br);
  min-height: 100vh;
}

.appFooter {
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  justify-content: center;
  bottom: 0;
  gap: 1rem;
  margin: 20px 50px;

  a {
    color: var(--caption-text-color);
  }

  a:hover {
    color: var(--primary) !important;
  }
}

.logoWrap {
  padding: 16px 0;
  color: var(--primary);
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  label {
    font-weight: var(--bold);
    font-size: 2rem;
    color: inherit;
  }

  .mainLogo {
    cursor: pointer;
    min-height: 32px;

    label {
      cursor: inherit;
    }

    img {
      opacity: 1;
      height: 60px;
      overflow: hidden;
      color: inherit;
    }

    @media screen and (max-width: 1200px) {
      label {
        font-size: 1.5rem;
      }

      img {
        height: 50px;
      }
    }

    @media screen and (max-width: 576px) {
      label {
        font-size: 1.25rem;
      }

      img {
        height: 40px;
      }
    }

    @media screen and (max-width: 480px) {
      gap: .5rem;
      padding: 2px 0;

      label {
        font-size: .875rem;
      }

      img {
        height: 32px;
      }
    }
  }
}
