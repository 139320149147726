.info {
  color: var(--text-primary);
  font-size: 16px;
  text-align: right;
  font-weight: 500;
  display: flex;
  gap: calc(var(--unit) * 4);
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  margin: 0;
  height: 100%;
  padding-top: 20px;

  svg {
    fill: var(--primary);
    animation: pulse 1s ease-in-out infinite;
    min-width: 16px;
  }

  @media (max-width: 768px) {
    padding-top: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    fill: var(--primary)
  }
  50% {
    fill: var(--primary-400);
  }
  100% {
    fill: var(--primary);
  }
}

@-o-keyframes pulse {
  0% {
    fill: var(--primary)
  }
  50% {
    fill: var(--primary-400);
  }
  100% {
    fill: var(--primary);
  }
}

@-moz-keyframes pulse {
  0% {
    fill: var(--primary)
  }
  50% {
    fill: var(--primary-400);
  }
  100% {
    fill: var(--primary);
  }
}

@keyframes pulse {
  0% {
    fill: var(--primary)
  }
  50% {
    fill: var(--primary-400);
  }
  100% {
    fill: var(--primary);
  }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: var(--medium);
  font-size: 1rem;
  gap: .5rem;

  label {
    font-size: inherit;
    font-weight: inherit;
    color: color-mix(in srgb, var(--text-primary), #fff 20%);
  }

  svg {
    flex-shrink: 0;
  }
}