.wrapLoadingFullPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.49);

  .spinner {
    top: 50%;
    left: 50%;
    z-index: 999;
    position: absolute;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.containerLoader {
  display: flex;
  justify-content: center;
  margin: .5rem;
}


